import React from "react";
import {render} from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "mobx-react";
import {Helmet} from "react-helmet";
import "moment/locale/fr";
import {ConfigProvider} from "antd";
import frFR from "antd/es/locale/fr_FR";
import TagManager from "react-gtm-module";
import lang from "./lang/fr"
// Import Components
import App from "./app";
import Dictionary from "./stores/dictionary";
import Interface from "./stores/interface";
import List from "./stores/list";
import Detail from "./stores/detail";
import User from "./stores/user";
import dataDogLaunch from "./services/datadog.js";

const tagManagerArgs = {
  gtmId: "GTM-PKDPGJ9",
}

TagManager.initialize(tagManagerArgs);
if(process.env.REACT_APP_ENV) {
dataDogLaunch();
}

const store = {
  dictionary: Dictionary,
  interface: Interface,
  list: List,
  detail: Detail,
  user: User,
};

const router = (
  <div>
    <Helmet>
      <title>{lang.global.titreNavigateur.defaut}</title>
    </Helmet>
    <Provider {...store}>
      <Router>
        <ConfigProvider locale={frFR}>
          <App/>
        </ConfigProvider>
      </Router>
    </Provider>
  </div>
);

render(router, document.getElementById("root"));
