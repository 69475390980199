import { datadogRum } from '@datadog/browser-rum';
import {datadogLogs} from "@datadog/browser-logs";

export default function dataDogLaunch(){
    const unifiedParams = {
        clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
        site: "datadoghq.eu",
        service: "pilote-energie-front",
        env: process.env.REACT_APP_ENV,
        version: process.env.REACT_APP_VERSION,
    };
   try {
       const paramsRum= {
           ...unifiedParams,
           applicationId: process.env.REACT_APP_DD_APP_ID,
           sessionSampleRate: 100,
           sessionReplaySampleRate: 0,
           trackUserInteractions: true,
           trackResources: true,
           trackLongTasks: true,
           traceSampleRate: 100,
           defaultPrivacyLevel: 'mask-user-input'
       }
       const paramsLogs = {
           ...unifiedParams,
           forwardErrorsToLogs: true,
           sampleRate: 100
       }
       datadogRum.init(paramsRum);
       datadogLogs.init(paramsLogs);
   }
   catch(error){
       console.error("Error initializing DataDog", error);
   }
}
